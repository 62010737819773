import logo from './logo.svg';
import './App.css';

import ExpandHandshake from './images/expand-handshake-2.png';
import TwitterImg from './images/twitter_2_1.png';
import HandshakeLogoDark from './images/handshake-logo-dark.svg';
import Fingertip from './images/fingertip-logo.svg';
import { useState } from 'react';

function App() {
  const [hnsDomain, setHnsDomain] = useState('');

  const onGo = () => {
    if(hnsDomain.length > 0) {
      window.location = 'http://' + hnsDomain + '.hns.to';
    }
  };

  const onKeyUp = (e) => {
    if(e.keyCode == 13) {
      onGo();
    }
  };

  if(window.location.pathname != '' && window.location.pathname != '/') {
      var host = window.location.pathname.substring(1);
      var path = '';
      if(host.indexOf('/') != -1) {
        path = host.substring(host.indexOf('/'));
        host = host.substring(0, host.indexOf('/'));
      }
      window.location = 'http://' + host + '.hns.to' + path;
      return (
        <p>Redirecting you to <span>{host}</span></p>
      )
  }

  return (
<div id="root">
<div className="sc-eCssSg TAkYY"><header className="sc-jSgupP hkoUsj"><div className="sc-gKsewC bPFGtq"><a href="https://hns.to/" className="sc-iBPRYJ fLyMi">HN<span className="sc-fubCfw cQAZfq Home-titleArrow">S↗T</span>O</a>&nbsp;</div><div style={{display:'contents'}}><div className="sc-pFZIQ klYlJB"><div className="sc-bdfBwQ jDEuhy">
<img src={HandshakeLogoDark} className="sc-hKgILt leCiMZ"/><input placeholder="Enter a Handshake name (e.g. welcome.namebase)" value={hnsDomain} onChange={(e) => setHnsDomain(e.target.value)} onKeyUp={onKeyUp} className="sc-gsTCUz gSbnkh"/><button onClick={onGo} className="sc-dlfnbm bgpqRC">→</button></div></div></div></header><main className="sc-jrAGrp gyZbmR"><div className="sc-iqHYGH fcbsDw"><div className="sc-crrsfI gRSEvu">Handshake (HNS)</div>
<div className="sc-bqyKva fwnTpE">
Handshake decentralizes the Internet by shifting control of the Domain Name System’s (DNS) root zone file away from ICANN and onto a decentralized blockchain. <br/><br/>

<div className="embed-youtube">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/oMeaxS-2ZoE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
</div>
</div>
<div className="sc-kstrdz dwvhAw"></div>

<div className="sc-crrsfI gRSEvu">HNS↗to</div>
<div className="sc-bqyKva fwnTpE">
<a href="http://hns.to">HNS↗to</a> is a gateway created by <a href="http://nijynot"><span className="white bold">nijynot/</span></a> that enables you to visit Handshake websites by appending <span className="white bold">.hns.to</span> to any Handshake domain.
<br/><br/>
<span className="white bold">Pros:</span> No configuration/installation needed <br/> 
<span className="white bold">Cons:</span> Need to append ".hns.to" every time, censorable, centralized, highly insecure
</div>
<br/><br/>

<div className="sc-crrsfI gRSEvu">HDNS.io</div>
<div className="sc-bqyKva fwnTpE">
<a href="http://hdns.io">HDNS.io</a> is a public resolver created by <a href="https://www.namebase.io">Namebase</a> that enables you to access Handshake domains by changing your DNS settings.
<br/><br/>
<span className="white bold">Pros:</span> Set up once and resolve Handshake domains seamlessly, no installation <br/>
<span className="white bold">Cons:</span> Requires editing DNS, censorable, centralized
</div>

<a href="https://www.hdns.io/" className="sc-hHftDr cULJNF"><div className="sc-dmlrTW EsoD"><div className="sc-kfzAmx jhXrZs">HDNS - Handshake DNS</div><div className="sc-fKFyDc cjbqYu">Access the Decentralized Internet on any device.</div></div><div className="sc-iwyYcG eZezEo">
<img src={TwitterImg} className="sc-bBXqnf dFkesR"/></div></a><div className="sc-kstrdz dwvhAw"></div>

<br/><br/>

<div className="sc-crrsfI gRSEvu">Fingertip</div>
<div className="sc-bqyKva fwnTpE">
<a href="https://impervious.com/fingertip.html">Fingertip</a> is a decentralized resolver created by Impervious that that enables you to trustlessly resolve Handshake names.
<br/><br/>
<span className="white bold">Pros:</span> Set up once and resolve Handshake domains seamlessly, 1-click installation, uncensorable <br/>
<span className="white bold">Cons:</span> Requires installing something, only supported on desktop (if on iOS, check out <a href="https://impervious.com/beacon">Beacon</a>)
</div>

<a href="https://impervious.com/fingertip.html" className="sc-hHftDr cULJNF"><div className="sc-dmlrTW EsoD"><div className="sc-kfzAmx jhXrZs">Fingertip</div><div className="sc-fKFyDc cjbqYu">A lightweight, decentralized Handshake resolver.</div></div>
<div className="sc-fKFyDc cjbqYu" style={{textAlign: 'center', margin: 'auto'}}>
<img src={Fingertip} style={{width: '50%'}} className="sc-bBXqnf dFkesR"/></div></a><div className="sc-kstrdz dwvhAw"></div>


<br/>

<div className="sc-crrsfI gRSEvu">Learn more</div><div className="sc-bqyKva fwnTpE">
<p>Check out <a href="https://learn.namebase.io/starting-from-zero/how-to-access-handshake-sites">Namebase’s Learning Center</a> for more ways to access Handshake.</p>

  <a href="https://community.namebase.io/" className="sc-hHftDr cULJNF"><div className="sc-dmlrTW EsoD"><div className="sc-kfzAmx jhXrZs">Namer Community's Resources</div><div className="sc-fKFyDc cjbqYu">The Namer Community's mission is to help Handshake names reach their fullest potential.</div></div><div className="sc-iwyYcG eZezEo">
<img src="" className="sc-bBXqnf dFkesR"/></div></a><br/><br/>

  </div>
  
  </div></main>
  
  </div>

<div style={{textAlign: 'center', padding: '35px', width: '100%', backgroundColor: '#000000', color: 'rgba(255,255,255,.8)'}}>
  <p>
  HNS.to is a gateway created by <a href="http://nijynot"><span className="white bold">nijynot/</span></a> that is now maintained by <a href="https://www.namebase.io">Namebase</a>.
  </p>
  <br/>
Visit the <a href="https://community.namebase.io/">Namer Community</a> to learn more about Handshake and how to get involved!

</div>

</div>
  );
}

export default App;
